.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  background-color: #d0d4db;
  line-height: 1.5;
  cursor: pointer;
  color: #000;
  font-family: 'FrutigerBold', sans-serif;
  padding: 7px 50px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border: 1px solid #000;
  float: right;
}
.button:hover,
.button:focus {
  color: #000;
  background-color: #e27c00;
}
.button:active {
  background-color: #e27c00;
}
#home {
  float: left;
  width: 350px;
}
.section--header .desk {
  width: 1280px;
  max-width: 90%;
  margin-top: 20px;
  margin-bottom: 20px;
  min-height: 75px;
}
h1,
h2 {
  font-size: 28px;
  line-height: 1.17857143;
}
h1 strong,
h2 strong {
  font-size: 42px;
  line-height: 1.19047619;
}
h1 i,
h2 i {
  font-size: 42px;
  line-height: 1.19047619;
}
h3 {
  font-size: 28px;
  line-height: 1.17857143;
  font-weight: normal;
  text-align: center;
  letter-spacing: 0.1em;
}
h3 strong {
  font-weight: 800;
  font-size: 28px;
  line-height: 1.17857143;
}
h4 {
  font-size: 28px;
  line-height: 1.17857143;
}
h4 strong {
  font-size: 28px;
  line-height: 1.17857143;
}
.section--beta .area {
  width: 100%;
}
.section--beta .area > .unit {
  margin-right: 1.35135135%;
  margin-left: 1.35135135%;
  width: 97.2972973%;
}
.section--beta .area .cb-album .body,
.section--beta .area .head,
.section--beta .area .foot,
.section--beta .area .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--beta .area > .slim {
  width: 47.2972973%;
}
.section--beta .area > .slim .head,
.section--beta .area > .slim .foot,
.section--beta .area > .slim .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--beta .area > .slim.cb-album .body {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--beta .area > .slim .tiny {
  width: 100%;
}
.section--beta .area {
  width: 102.77777778%;
  margin-left: -1.38888889%;
  display: flex;
  flex-wrap: wrap;
}
.section--beta .area .unit.slim .body {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.section--beta .area .unit.slim .part.tiny {
  width: calc(50% - 15px);
}
.section--alpha .area .flat.wide .part.tall {
  width: 58.88888889vw;
  max-width: 1080px;
  margin-left: 50%;
  transform: translateX(-50%);
}
/*# sourceMappingURL=./screen-medium.css.map */